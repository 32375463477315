/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import MarkdownIt from 'markdown-it';
import cx from 'classnames';
import { DATA_ROW_CONTENTFUL_ID } from '@/src/hooks/useVerticalIndex';
import s from './GenericPageComponents.module.scss';

const SUPPORTED_CONTENTFUL_MODELS = [
  'quote',
  'hero',
  'expandableTextSection',
  'video',
  'twoColumnContainer',
  'links',
  'timeline',
  'discoverProductCollection',
  'customComponent',
  'textSection',
  'countdown',
  'quizEvent',
  'flexibleCarousel',
  'flexibleContainer',
  'contentList',
  'fullWidthPromo',
  'userIdentityChecklist',
];

const propTypes = {
  contentSlots: PropTypes.array.isRequired,
  theme: PropTypes.string,
  isModal: PropTypes.bool,
  patronusData: PropTypes.array,
};

const convertToMarkdown = (value) => {
  const md = new MarkdownIt({
    html: true,
    linkify: false,
  });
  return md.render(value);
};

const renderContent = (content, theme, isModal, patronusData) => {
  switch (content.contentTypeId) {
    case 'quote': {
      const Quote = dynamic(import('../../pages/Article/components/ArticlePullQuote'));
      return <Quote quote={content} />;
    }
    case 'hero': {
      if (content.heroDepth === 'with-cta') {
        const HeroWithCta = dynamic(import('../HeroWithCta'));
        return <HeroWithCta {...content} />;
      }
      if (content.heroDepth === 'discover') {
        const DiscoverHero = dynamic(import('../DiscoverHero'));
        return <DiscoverHero {...content} />;
      }
      if (content.heroDepth === 'discover-product') {
        const DiscoverProductHero = dynamic(import('../DiscoverProductHero'));
        return <DiscoverProductHero {...content} />;
      }

      const Hero = dynamic(import('../Hero'));
      return (
        <Hero
          {...content}
          image={content.backgroundImage ? `${content.backgroundImage.file.url}?w=1920` : null}
          image2x={content.backgroundImage ? `${content.backgroundImage.file.url}?w=3840` : null}
          mobileImage={
            content.mobileBackgroundImage ? `${content.mobileBackgroundImage.image.file.url}` : null
          }
          title={content.displayTitle}
          scrollIndicator
          modalHero={isModal}
        />
      );
    }
    case 'expandableTextSection': {
      const ExpandableTextSection = dynamic(import('../ExpandableTextSection'));
      return <ExpandableTextSection section={content} />;
    }
    case 'textSection': {
      const TextSection = dynamic(import('../../pages/Article/components/ArticleCopy'));
      content.text = convertToMarkdown(content?.text);
      return (
        <TextSection
          className={cx(s.hundredPercent, s.textSection)}
          section={content}
          key={`${content.contentfulId}`}
        />
      );
    }
    case 'video': {
      const PromoVideoPlayer = dynamic(import('../PromoVideoPlayer'));
      return <PromoVideoPlayer videoData={content} />;
    }
    case 'twoColumnContainer': {
      const TwoColumn = dynamic(import('../TwoColumn'), {
        ssr: false,
      });
      return <TwoColumn contentSlots={content.contentSlots} />;
    }
    case 'links': {
      // TODO: This feels gross and should be refactored in the future so we don't have two
      // kinds of promos supported this way in generic page.
      if (theme === 'fan-club') {
        const FanClubPromo = dynamic(import('../FanClubPromo'));
        return <FanClubPromo {...content} image={content?.mainImage?.image?.file?.url} />;
      }

      const GenericPromo = dynamic(import('../GenericPromo'));
      return <GenericPromo {...content} />;
    }
    case 'timeline': {
      const Timeline = dynamic(import('../Timeline'));
      return <Timeline {...content} />;
    }
    case 'discoverProductCollection': {
      const DiscoverProductCollection = dynamic(import('../DiscoverProductCollection'));
      return <DiscoverProductCollection {...content} />;
    }
    case 'countdown': {
      const Countdown = dynamic(import('../Countdown'));
      return <Countdown {...content} />;
    }
    case 'quizEvent': {
      const { quizzes } = content;
      quizzes.forEach((quiz, index) => {
        // Massaging data because quiz and quiz list check for tags further downstream
        // TODO: after custom component quiz events are fully removed, use data pulled from
        // the quizEvent and not from customFields props or tags.

        const quizNumberLabel = `${content?.quizNumberLabel || 'week-'} ${index + 1}`;
        const challengeId = `challenge-${content?.eventId}`;

        const prunedDisplayTags = quiz?.displayTag.filter((tag) => {
          return !tag?.name.includes('week-') && !tag?.name.includes('challenge-');
        });

        if (!quiz.tags) quiz.tags = [];
        quiz.displayTag = prunedDisplayTags;
        quiz.displayTag.unshift({ name: quizNumberLabel });
        quiz.tags.push({ name: challengeId });
      });

      const QuizLeaderboard = dynamic(import('../QuizLeaderboard'));
      const QuizList = dynamic(import('../QuizList'));
      return (
        <div className={s.quizEvent}>
          <QuizLeaderboard
            eyebrow={content?.leaderboardEyebrow}
            header={content?.leaderboardHeader}
            subheader={content?.leaderboardSubheader}
            customField1={{ text: content?.eventId }}
            customField2={{ image: content?.leaderboardNoHouseBackground }}
            customField3={{ image: content?.leaderboardGryffindorBackground }}
            customField4={{ image: content?.leaderboardRavenclawBackground }}
            customField5={{ image: content?.leaderboardHufflepuffBackground }}
            customField6={{ image: content?.leaderboardSlytherinBackground }}
            customField7={{ text: content?.winnerStateActivated.toString() }}
            {...content}
          />
          {!content.winnerStateActivated && (
            <QuizList
              contentSlots={quizzes}
              customField1={{ text: content.allQuizzesCompletedText, contentType: 'textsection' }}
            />
          )}
        </div>
      );
    }
    case 'flexibleCarousel': {
      const FlexibleCarousel = dynamic(import('../DynamicComponents/FlexibleCarousel'));
      return <FlexibleCarousel {...content} />;
    }
    case 'flexibleContainer': {
      const FlexibleContainer = dynamic(import('../DynamicComponents/FlexibleContainer'));
      return <FlexibleContainer {...content} />;
    }
    case 'contentList': {
      const MultiTilePromo = dynamic(import('../DynamicComponents/MultiTilePromo'));
      return <MultiTilePromo {...content} />;
    }
    case 'fullWidthPromo': {
      const FullWidthBanner = dynamic(import('../DynamicComponents/FullWidthBanner'));
      return <FullWidthBanner {...content} />;
    }
    case 'userIdentityChecklist': {
      const UserIdentityChecklist = dynamic(
        import('@components/DynamicComponents/UserIdentityChecklist'),
      );
      return (
        <UserIdentityChecklist
          patronusData={patronusData}
          analyticsParams={content}
          header={content?.header}
          housePercentages={{
            gryffindor: content?.gryffindorPercentage || 32,
            hufflepuff: content?.hufflepuffPercentage || 40,
            ravenclaw: content?.ravenclawPercentage || 12,
            slytherin: content?.slytherinPercentage || 16,
          }}
        />
      );
    }
    case 'customComponent': {
      const CustomComponent = dynamic(import('../CustomComponent'));
      return <CustomComponent {...content} />;
    }
    // istanbul ignore next
    default:
      return null;
  }
};

// alternate the left/right placement of promos in the generic page
// by setting a leftAligned field on each one.
const indexPromoSlots = (contentSlots) => {
  let promoIndex = 0;
  contentSlots.forEach((content, index) => {
    content.promoIndex = index;
    if (content.contentTypeId === 'links') {
      content.leftAligned = promoIndex % 2 !== 0;
      promoIndex += 1;
    }
  });
};

const GenericPageComponents = ({
  contentSlots,
  isModal = false,
  theme = '',
  patronusData = [],
}) => {
  indexPromoSlots(contentSlots);
  return (
    <div
      className={[s.genericPageContainer, isModal ? s.genericPageModal : null].join(' ')}
      data-themed="true"
    >
      {contentSlots.map((content, index) => {
        // if the content slot item is not a supported type then do not render it
        if (!SUPPORTED_CONTENTFUL_MODELS.includes(content.contentTypeId)) return null;

        const additionalAttributes = {
          [DATA_ROW_CONTENTFUL_ID]: content.contentfulId,
        };

        return (
          <div
            className={cx(s.section, {
              hero: index === 0,
              [s.hero]: index === 0,
              [s.shiftUp]: contentSlots?.[0]?.heroDepth === 'shallow' && index === 1,
              [s.bottomOverlap]: content.componentName === 'QuizLeaderboard',
              [s.fullWidth]:
                ['hero', 'timeline', 'discoverProductCollection', 'quizEvent'].includes(
                  content.contentTypeId,
                ) ||
                ['SocialCarousel', 'QuizLeaderboard', 'PromoRow', 'Spotlight'].includes(
                  content.componentName,
                ),
              [s.canBeHidden]: ['HouseSortingStats'].includes(content.componentName),
              [s.noMarginTop]: [
                'quote',
                'expandableTextSection',
                'flexibleCarousel',
                'flexibleContainer',
                'fullWidthPromo',
                'countdown',
              ].includes(content.contentTypeId),
            })}
            key={content.contentfulId}
            {...additionalAttributes}
          >
            {renderContent(content, theme, isModal, patronusData)}
          </div>
        );
      })}
    </div>
  );
};

GenericPageComponents.propTypes = propTypes;
export default GenericPageComponents;
